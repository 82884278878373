import { ReactElement, useEffect, useReducer, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useDebounce } from 'hooks/useDebounce'
import { definitionFields, firstColumn } from './InvoiceListTable.definitionFields'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order, IInvoicesGet, InvoiceClass } from '@Arti-zen/package-backoffice'
import { IInvoiceList } from '../InvoiceList.interfaces'
import { invoiceListActions } from 'modules/invoiceList/store/store.invoiceList.reducer'
import { useNavigate } from 'react-router-dom'

export default function InvoiceListTable({ search = null, filterBase = {} }: Readonly<IInvoiceList>): ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { companyIds } = useAppSelector((state) => state.artisan)
  const { invoices, isQueriedAllCompleted } = useAppSelector((state) => state.invoiceList)

  const [noFilter, setNoFilter] = useState(true)
  const [currentSort, setCurrentSort] = useReducer(sortReducer, [
    { key: 'creationDate', order: Order.DESC },
    { key: 'number', order: Order.DESC },
  ])

  const fields = definitionFields(navigate)
  const [displayedFields, setDisplayedFields] = useState(fields.map((field) => field.indexName))

  const searchText = useDebounce(search, 500)

  const refresh = () => {
    if (!filterBase?.status || filterBase.status.length === 0) return setNoFilter(true)
    setNoFilter(false)

    const newFilters: IInvoicesGet = {
      userIds: companyIds,
      page: 1,
      limit: 15,
      ...filterBase,
      searchText: searchText,
      sort: currentSort,
    }

    dispatch(invoiceListActions.fetch(newFilters))
  }

  useEffect(() => {
    refresh()
  }, [searchText, filterBase, currentSort])

  const nextPage = () => {
    if (!isQueriedAllCompleted) {
      dispatch(invoiceListActions.fetchNextPage())
    } else {
      return null
    }
  }

  return (
    <TableInfinite<InvoiceClass['meilisearchDoc']>
      definitionFields={fields}
      firstColumn={firstColumn}
      displayedFields={displayedFields}
      setDisplayedFields={setDisplayedFields}
      data={noFilter ? [] : invoices}
      nextPage={nextPage}
      isQueriedAllCompleted={noFilter || isQueriedAllCompleted}
      onSort={(indexName, order) => setCurrentSort({ key: indexName, order })}
      activeSorts={currentSort}
      displayError={noFilter ? 'Sélectionner au moins un statut de facture.' : null}
    />
  )
}

const sortReducer = (
  _state,
  action: { key: keyof InvoiceClass['meilisearchDoc']; order: Order }
): IInvoicesGet['sort'] => {
  const { key, order } = action

  switch (key) {
    case 'creationDate':
      return [
        { key: 'creationDate', order },
        { key: 'number', order: Order.DESC },
      ]
    case 'number':
      return [
        { key: 'number', order },
        { key: 'creationDate', order: Order.DESC },
      ]
    default:
      return [
        { key, order },
        { key: 'creationDate', order: Order.DESC },
        { key: 'number', order: Order.DESC },
      ]
  }
}
