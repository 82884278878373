export const supportPages = {
  root: '/support',
  invoiceQuotationMissing: {
    name: '/support/invoiceQuotationMissing',
    navigate: () => `/support/invoiceQuotationMissing`,
  },
  companyLastDocs: {
    name: '/support/companyLastDocs',
    navigate: () => `/support/companyLastDocs`,
  },
}
