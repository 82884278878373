import { ReactElement } from 'react'
import { Route } from 'react-router-dom'
import { pages } from 'navigation/pages'
import { Roles } from '@Arti-zen/package-backoffice'
import { RouteProtected } from 'navigation/navigation.helper'
import SupportLayout from './SupportLayout'
import InvoiceQuotationMissingList from '../components/InvoiceQuotationMissingList'
import CompanyLastDocList from '../components/CompanyLastDocList/CompanyLastDocList'

export default function SupportRouter(): ReactElement {
  return (
    <Route
      path={pages.connected.support.root}
      element={RouteProtected(<SupportLayout />, [Roles.SUPER_ADMIN, Roles.ADMIN])}
    >
      <Route path={pages.connected.support.invoiceQuotationMissing.name} element={<InvoiceQuotationMissingList />} />
      <Route path={pages.connected.support.companyLastDocs.name} element={<CompanyLastDocList />} />
    </Route>
  )
}
