import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import styled from 'styled-components'
import CsvDownloadButton from 'react-json-to-csv'
import { Format } from 'utils/format'
import { allMonth } from './artisanDashboard.helpers'

export default function ArtisanDashboardClassic(): ReactElement {
  const { dateStart, dateEnd, interventions, quotations, invoices } = useAppSelector((state) => state.artisan.dashboard)

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
        <table style={{ border: 'solid 1px', margin: 20 }}>
          <tr>
            <th>Type Document</th>
            {allMonth({
              dateStart,
              dateEnd,
              action: (year, month) => <th style={{ padding: 10, textAlign: 'center' }}>{year + '/' + month}</th>,
            })}
          </tr>
          <tr>
            <td>Interventions</td>
            {allMonth({
              dateStart,
              dateEnd,
              action: (year, month) => {
                const interList = interventions.filter((inter) => {
                  const date = new Date(inter.interventionStartDate)
                  return date.getMonth() + 1 === month && date.getFullYear() === year
                })

                return (
                  <td style={{ padding: 10, textAlign: 'center' }}>
                    <div>{interList.length}</div>
                  </td>
                )
              },
            })}
          </tr>
          <tr>
            <td>Devis</td>
            {allMonth({
              dateStart,
              dateEnd,
              action: (year, month) => {
                const quotList = quotations.filter((quot) => {
                  const date = new Date(quot.creationDate)
                  return date.getMonth() + 1 === month && date.getFullYear() === year
                })

                return (
                  <td style={{ padding: 10, textAlign: 'center' }}>
                    <div>{quotList.length}</div>
                  </td>
                )
              },
            })}
          </tr>
          <tr>
            <td>Factures</td>
            {allMonth({
              dateStart,
              dateEnd,
              action: (year, month) => {
                const invList = invoices.filter((inv) => {
                  const date = new Date(inv.creationDate)
                  return date.getMonth() + 1 === month && date.getFullYear() === year
                })

                return (
                  <td style={{ padding: 10, textAlign: 'center' }}>
                    <div>{invList.length}</div>
                    <div>{Format.currency(invList.reduce((acc, inv) => acc + inv.totalHT_cts, 0) / 100)}</div>
                  </td>
                )
              },
            })}
          </tr>
        </table>
      </div>

      <CsvDownloadButton data={interventions} title="interventions" style={{ margin: 5 }}>
        Télécharger les interventions
      </CsvDownloadButton>
      <CsvDownloadButton data={quotations} title="quotations" style={{ margin: 5 }}>
        Télécharger les devis
      </CsvDownloadButton>
      <CsvDownloadButton data={invoices} title="invoices" style={{ margin: 5 }}>
        Télécharger les factures
      </CsvDownloadButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
