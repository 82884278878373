import { ReactElement, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite, Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { UserArtizen } from '@Arti-zen/package-backoffice'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Format } from 'utils/format'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { ApiArtizenBO } from 'utils/artizenConnector'
import CsvDownloadButton from 'react-json-to-csv'
import { TFactureFulFilled, factFulfilled } from 'utils/stats'

const annualFactures: { clientId: number; totalHtCts: number }[] = [
  {
    clientId: 4232,
    totalHtCts: 8500,
  },
  {
    clientId: 1880,
    totalHtCts: 5000,
  },
  {
    clientId: 2000,
    totalHtCts: 3500,
  },
  {
    clientId: 1747,
    totalHtCts: 3500,
  },
]

const exceptions = [3874, 3904] //2874 facturé via 3872, 3904 facturé via 3954

const definitionFields = (navigate: NavigateFunction): ITableInfinite<TFactureFulFilled>['definitionFields'] => {
  return [
    {
      indexName: 'ref',
      label: 'Numéro de facture',
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'clientId',
      label: 'Id client',
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'createdAt',
      label: 'date facture',
      format: ({ createdAt }) => Format.dateFr(createdAt),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'amount',
      label: 'Montant HT',
      format: ({ amount }) => Format.currency(amount, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'mainHtCts',
      label: 'Abonnement principal',
      format: ({ mainHtCts }) => Format.currency(mainHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'subHtCts',
      label: 'Sous-Comptes',
      format: ({ subHtCts }) => Format.currency(subHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'promoHtCts',
      label: 'Promo (abs)',
      format: ({ promoHtCts }) => Format.currency(promoHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'optionsHtCts',
      label: 'Options',
      format: ({ optionsHtCts }) => Format.currency(optionsHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
  ]
}

export default function DashboardMrrOneShot(): ReactElement {
  const navigate = useNavigate()
  const [dateEnd, setDateEnd] = useState<DateTime>(DateTime.now().setZone('Europe/Paris').endOf('day'))
  const [factures, setFactures] = useState<TFactureFulFilled[]>([])
  const [artisanBossList, setArtisanBossList] = useState<UserArtizen[]>([])
  const [missingFactures, setMissingFactures] = useState<UserArtizen[]>([])
  const fields = definitionFields(navigate)

  const onSort = () => console.log('sort')

  const [displayedFields, setDisplayedFields] = useState<(keyof TFactureFulFilled)[]>(
    fields.map((def) => def.indexName)
  )
  const activeSort = [{ key: 'createdAt', order: Order.DESC }]

  useEffect(() => {
    let isMount = true
    ApiArtizenBO.artizenUsers.get().then((users) => {
      isMount &&
        setArtisanBossList(
          users.filter((user) => {
            return !user?.parentId && !!user?.subscriptionDate && !user.demoAccount
          })
        )
    })

    return () => {
      isMount = false
    }
  }, [])

  useEffect(() => {
    let isMount = true

    ApiArtizenBO.facturation
      .getAll({
        dateStart: dateEnd.minus({ month: 1 }).startOf('day').toString(),
        dateEnd: dateEnd.toString(),
      })
      .then((factureList) => {
        const artisanFiltered = artisanBossList.filter((user) => {
          const tests = [
            //Subscription test
            dateEnd.diff(DateTime.fromJSDate(new Date(user.subscriptionDate))).milliseconds >= 0,
            //Resiliation test
            !user.resiliationDatetime ||
              dateEnd.diff(DateTime.fromJSDate(new Date(user.resiliationDatetime))).milliseconds <= 0,
          ]

          return tests.every((val) => val === true)
        })

        const missingFactArti = []
        const facts = []

        artisanFiltered.forEach((artisan) => {
          const fact = factureList.find((fact) => +fact.clientId === +artisan.id)

          if (!fact) {
            missingFactArti.push(artisan)
          } else {
            facts.push(factFulfilled(fact))
          }
        })

        if (isMount) {
          setFactures(facts)
          setMissingFactures(
            missingFactArti.filter(
              (arti) => ![...annualFactures.map((fact) => fact.clientId), ...exceptions].includes(arti.id)
            )
          )
        }
      })

    return () => {
      isMount = false
    }
  }, [dateEnd, artisanBossList])

  const stats = useMemo(() => {
    return factures.reduce(
      (totaux, fact) => {
        return {
          totalMainHtCts: totaux.totalMainHtCts + fact.mainHtCts,
          totalSubHtCts: totaux.totalSubHtCts + fact.subHtCts,
          totalPromoHtCts: totaux.totalPromoHtCts + fact.promoHtCts,
          totalOptionsHtCts: totaux.totalOptionsHtCts + fact.optionsHtCts,
        }
      },
      {
        totalMainHtCts: annualFactures.reduce((sum, fact) => fact.totalHtCts + sum, 0),
        totalPromoHtCts: 0,
        totalSubHtCts: 0,
        totalOptionsHtCts: 0,
      }
    )
  }, [factures])

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 20, marginTop: 50 }}>
        <DatePicker label="Date de fin" value={dateEnd} onChange={setDateEnd} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            Missing factures : {missingFactures.length}
            <CsvDownloadButton
              data={missingFactures}
              filename={`MRR - Missing factures au ${dateEnd.toFormat('yyyy/MM/dd')}`}
              style={{ margin: 5 }}
            >
              Télécharger
            </CsvDownloadButton>
          </div>
          <div>
            Détail MRR : {factures.length + annualFactures.length}
            <CsvDownloadButton
              data={[...factures, ...annualFactures].map((fact) => ({
                email: artisanBossList.find((arti) => arti.id === fact.clientId)?.email,
                ...fact,
              }))}
              title="MRR_detail"
              filename={`MRR au ${dateEnd.toFormat('yyyy/MM/dd')}`}
              style={{ margin: 5 }}
            >
              Télécharger
            </CsvDownloadButton>
          </div>
          <div>
            Artisans
            <CsvDownloadButton
              data={artisanBossList}
              filename={`MRR - Artisans abonnés au ${dateEnd.toFormat('yyyy/MM/dd')}`}
              style={{ margin: 5 }}
            >
              Télécharger
            </CsvDownloadButton>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 100 }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
          <DisplayStat name="Abonnements HT" value={Format.currency(stats.totalMainHtCts / 100, 2)} />
          <DisplayStat name="Comptes Salariés HT" value={Format.currency(stats.totalSubHtCts / 100, 2)} />
          <DisplayStat name="Options HT" value={Format.currency(stats.totalOptionsHtCts / 100, 2)} />
          <DisplayStat
            isTotal
            name="Total HT Brut"
            value={Format.currency((stats.totalMainHtCts + stats.totalSubHtCts + stats.totalOptionsHtCts) / 100, 2)}
          />
          <DisplayStat name="Promos HT" value={Format.currency(-stats.totalPromoHtCts / 100, 2)} />
          <DisplayStat
            isTotal
            name="Total HT Net (MRR)"
            value={Format.currency(
              (stats.totalMainHtCts + stats.totalSubHtCts + stats.totalOptionsHtCts - stats.totalPromoHtCts) / 100,
              2
            )}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
          <DisplayStat name="Nb factures" value={String(factures.length + annualFactures.length)} />
          <DisplayStat
            name="Facture HT moyenne"
            value={Format.currency(
              (stats.totalMainHtCts + stats.totalSubHtCts + stats.totalOptionsHtCts - stats.totalPromoHtCts) /
                100 /
                factures.length,
              2
            )}
          />
        </div>
      </div>

      <WrapperContent>
        <TableInfinite<TFactureFulFilled>
          displayedFields={displayedFields}
          setDisplayedFields={setDisplayedFields}
          definitionFields={fields}
          onSort={onSort}
          activeSorts={activeSort}
          data={factures}
          nextPage={() => {}}
          isQueriedAllCompleted={true}
          firstColumn={{
            format: () => <></>,
          }}
        />
      </WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`

const DisplayStat = ({ name, value, isTotal = false }: { name: string; value: string; isTotal?: boolean }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        ...(isTotal ? { fontWeight: 600, borderTop: 'solid 1px' } : {}),
      }}
    >
      <div style={{ display: 'flex', flex: 3 }}>{name}</div>
      <div style={{ display: 'flex', flex: 1 }}>{value}</div>
    </div>
  )
}
