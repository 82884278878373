import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { UserArtizen } from '@Arti-zen/package-backoffice'
import { Format } from 'utils/format'
import { TFactureFulFilled } from 'utils/stats'
import { Link } from 'react-router-dom'
import { pages } from 'navigation/pages'

export type TFactureFulFilledExtended = TFactureFulFilled & { artisan: UserArtizen }

export const definitionFields = (): ITableInfinite<TFactureFulFilledExtended>['definitionFields'] => {
  return [
    {
      indexName: 'ref',
      label: 'Numéro de facture',
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'clientId',
      label: 'Arisan Id',
      format: ({ clientId }) => (
        <Link to={pages.connected.artisan.profile.navigate(clientId)} target="_blank" rel="noopener noreferrer">
          {clientId}
        </Link>
      ),
    },
    {
      indexName: 'artisan',
      label: 'Artisan',
      format: ({ clientId, artisan }) => (
        <Link to={pages.connected.artisan.profile.navigate(clientId)} target="_blank" rel="noopener noreferrer">
          {artisan?.companyName}
        </Link>
      ),
    },
    {
      indexName: 'createdAt',
      label: 'date facture',
      format: ({ createdAt }) => Format.dateFr(createdAt),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'amount',
      label: 'Montant HT',
      format: ({ amount }) => Format.currency(amount, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'mainHtCts',
      label: 'Abonnement principal',
      format: ({ mainHtCts }) => Format.currency(mainHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'subHtCts',
      label: 'Sous-Comptes',
      format: ({ subHtCts }) => Format.currency(subHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'promoHtCts',
      label: 'Promo (abs)',
      format: ({ promoHtCts }) => Format.currency(promoHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'optionsHtCts',
      label: 'Options',
      format: ({ optionsHtCts }) => Format.currency(optionsHtCts / 100, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
  ]
}
