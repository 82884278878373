import { ReactElement } from 'react'
import { Route } from 'react-router-dom'
import ArtisanHome from 'modules/artisan/components/ArtisanProfile'
import ArtisanLayout from './ArtisanLayout'
import { pages } from 'navigation/pages'
import ArtisanQuotationList from '../components/ArtisanQuotationList'
import ArtisanInvoiceList from '../components/ArtisanInvoiceList'
import ArtisanInterventionList from '../components/ArtisanInterventionList'
import ArtisanClientList from '../components/ArtisanClientList'
import ArtisanDashboard from '../components/ArtisanDashboard/ArtisanDashboard'
import ArtisanFacturation from '../components/ArtisanFacturation'
import ArtisanSubscription from '../components/ArtisanSubscription/ArtisanSubscription'
import ArtisanExtracts from '../components/ArtisanExtracts'

export default function ArtisanRouter(): ReactElement {
  return (
    <Route path={pages.connected.artisan.root} element={<ArtisanLayout />}>
      <Route path={pages.connected.artisan.profile.name} element={<ArtisanHome />} />
      <Route path={pages.connected.artisan.interventionList.name} element={<ArtisanInterventionList />} />
      <Route path={pages.connected.artisan.quotationList.name} element={<ArtisanQuotationList />} />
      <Route path={pages.connected.artisan.invoiceList.name} element={<ArtisanInvoiceList />} />
      <Route path={pages.connected.artisan.clientList.name} element={<ArtisanClientList />} />
      <Route path={pages.connected.artisan.dashboard.name} element={<ArtisanDashboard />} />
      <Route path={pages.connected.artisan.facturation.name} element={<ArtisanFacturation />} />
      <Route path={pages.connected.artisan.subscription.name} element={<ArtisanSubscription />} />
      <Route path={pages.connected.artisan.extracts.name} element={<ArtisanExtracts />} />
    </Route>
  )
}
