import { Facture, FactureErrorState, TPayment, UserArtizen } from '@Arti-zen/package-backoffice'
import {
  EPaymentProgressStatus,
  FactureExtended,
  IFacturationListState,
  PaymentProgressTranslate,
} from './store.facturation.interfaces'
import { DateTime, Interval } from 'luxon'

export const getPaymentGCL = (payments: TPayment[]) => {
  if (payments && payments.length === 1) return payments[0]
  return null
}

export const statusPaymentProgress = (payments: TPayment[]) => {
  if (payments && payments.length === 1) return PaymentProgressTranslate[payments[0].status]
  if (payments && payments.length > 1) return EPaymentProgressStatus.moreThanOne
  return EPaymentProgressStatus.unknown
}

export const factureExtended = (fact: Facture, userList?: UserArtizen[]): FactureExtended => {
  return {
    ...fact,
    ...(userList ? { client: userList.find((user) => user.id === fact.clientId) } : {}),
    errorState: fact.errorState ?? FactureErrorState.OK,
    paymentGCL: getPaymentGCL(fact.payments),
    paymentProgress: statusPayment(fact),
  }
}

export const applyFilters = (state: IFacturationListState) =>
  state.factures
    .filter((fact) => state.errorList.includes(fact.errorState))
    .filter((fact) => state.stateList.includes(fact.state))
    .filter((fact) => state.paymentStatusList.includes(fact.paymentProgress))
    .filter((fact) => {
      const client = fact.client
      if (!client) return true
      if (client.isDisactivated === state.deactivatedClient) return true
      return false
    })
    .filter((fact) => {
      if (!state.search) return true

      const tests = [
        !!fact.internalRef?.includes(state.search),
        !!fact.ref?.includes(state.search),
        !!fact.client?.email?.includes(state.search),
      ]

      return tests.some((val) => val === true)
    })

export const statusPayment = (fact: Facture) => {
  const payments = fact.payments ?? []

  if (payments.length === 0) return EPaymentProgressStatus.unknown

  const totalPaid = payments.reduce((acc, payment) => {
    if (PaymentProgressTranslate[payment.status] === EPaymentProgressStatus.paid) {
      return acc + (payment.amountInCts - payment.amountRefunded) / 100
    } else {
      return acc
    }
  }, 0)

  const totalInProgress = payments.reduce((acc, payment) => {
    if (PaymentProgressTranslate[payment.status] === EPaymentProgressStatus.inProgress) {
      return acc + (payment.amountInCts - payment.amountRefunded) / 100
    } else {
      return acc
    }
  }, 0)

  switch (true) {
    case Math.round(totalPaid - Number(fact.amountTTC)) === 0:
      return EPaymentProgressStatus.paid
    case totalPaid + totalInProgress === +fact.amountTTC:
      return EPaymentProgressStatus.inProgress
    case totalInProgress > 0:
      return EPaymentProgressStatus.inProgress
    case totalPaid > 0 && totalPaid < +fact.amountTTC:
      return EPaymentProgressStatus.partialLess
    case totalPaid > 0 && totalPaid > +fact.amountTTC:
      return EPaymentProgressStatus.partialSup
    default:
      return EPaymentProgressStatus.unpaid
  }
}

export const nbFactTheoCalc = (params: {
  dateStart: DateTime
  dateEnd: DateTime
  subscriptionDate: DateTime
  resilisationDate: DateTime
}): number => {
  const { dateStart, dateEnd, subscriptionDate, resilisationDate } = params

  const startDate = dateStart.minus({ day: 1 })

  const dayFact = subscriptionDate?.get('day')
  const dayStartDate = startDate.get('day')

  const dateFirstFactPeriod =
    dayFact >= dayStartDate ? startDate.set({ day: dayFact }) : startDate.set({ day: dayFact }).plus({ month: 1 })

  switch (true) {
    //1er cas : hors bornes
    case !subscriptionDate || (resilisationDate && dateFirstFactPeriod.toMillis() > resilisationDate.toMillis()):
      return 0
    case dateEnd.toMillis() < subscriptionDate.toMillis() || dateEnd.toMillis() < dateFirstFactPeriod.toMillis():
      return 0
    //2ème cas: pas de résiliation date ou résiliation après borne de fin
    case !resilisationDate || dateEnd.toMillis() < resilisationDate.toMillis():
      if (dateFirstFactPeriod.toMillis() > subscriptionDate.toMillis()) {
        return Math.ceil(Interval.fromDateTimes(dateFirstFactPeriod, dateEnd).length('months'))
      } else {
        return Math.ceil(Interval.fromDateTimes(subscriptionDate, dateEnd).length('months'))
      }
    default:
      if (dateFirstFactPeriod.toMillis() > subscriptionDate.toMillis()) {
        return Math.ceil(Interval.fromDateTimes(dateFirstFactPeriod, resilisationDate).length('months'))
      } else {
        return Math.ceil(Interval.fromDateTimes(subscriptionDate, resilisationDate).length('months'))
      }
  }
}

export const factTheo = (params: {
  dateStart: DateTime
  dateEnd: DateTime
  subscriptionDate: DateTime
  resilisationDate: DateTime
  clientId: number
}): string[] => {
  const { dateStart, dateEnd, subscriptionDate, resilisationDate, clientId } = params

  const startDate = dateStart.minus({ day: 1 })
  const endDate = resilisationDate && dateEnd.toMillis() > resilisationDate.toMillis() ? resilisationDate : dateEnd
  const dayFact = subscriptionDate?.get('day')
  const dayStartDate = startDate.get('day')

  let nextFactDate =
    dayFact > dayStartDate ? startDate.set({ day: dayFact }) : startDate.set({ day: dayFact }).plus({ month: 1 })
  const factInterNumList = []

  clientId === 2959 && console.log(clientId, nextFactDate.toISODate(), endDate.toISODate(), nextFactDate.toISODate())

  while (isDateABetweenDates(nextFactDate, { dateStart: subscriptionDate, dateEnd: endDate })) {
    factInterNumList.push(`${clientId}_${nextFactDate.get('year')}_${nextFactDate.get('month') - 1}`)
    nextFactDate = nextFactDate.plus({ month: 1 })
  }

  return factInterNumList
}

const isDateABetweenDates = (dateA: DateTime, dates: { dateStart: DateTime; dateEnd: DateTime }) => {
  return dateA.toMillis() >= dates.dateStart.toMillis() && dateA.toMillis() <= dates.dateEnd.toMillis()
}
