import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import styled from 'styled-components'
import DownloadButton from 'components/DownloadButton'
import { componentByMonth } from 'modules/dashboard/components/DashboardFigures/dashboardFigures.helpers'
import { Format } from 'utils/format'
import { Theme } from 'theme/theme'

const keys = ['quotation', 'invoice', 'cart']

export default function ArtisanDashboardPartners(): ReactElement {
  const { dateStart, dateEnd, partnerStats, partnerStatList } = useAppSelector((state) => state.artisan.dashboard)

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: 20,
        }}
      >
        <DownloadButton data={partnerStatList} fileName="commandes partners details" />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
        <table style={{ border: 'solid 1px', margin: 20 }}>
          <tr>
            <th></th>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => <th style={{ padding: 10, textAlign: 'center' }}>{year + '/' + month}</th>,
            })}
          </tr>

          <>
            {!partnerStats
              ? null
              : keys.map((key) => (
                  <>
                    <tr style={{ fontWeight: 600, color: Theme.colors.blueArtizen }}>
                      <td>{key}</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'right' }}>Nombre</td>
                      {componentByMonth({
                        dateStart,
                        dateEnd,
                        component: (year, month) => (
                          <td style={{ padding: 10, textAlign: 'center' }}>
                            <div>{partnerStats[year]?.[month]?.[key]?.count}</div>
                          </td>
                        ),
                      })}
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'right' }}>User Unique</td>
                      {componentByMonth({
                        dateStart,
                        dateEnd,
                        component: (year, month) => (
                          <td style={{ padding: 10, textAlign: 'center' }}>
                            <div>{partnerStats[year]?.[month]?.[key]?.uniqueUserList.length}</div>
                          </td>
                        ),
                      })}
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'right' }}>Nb produit</td>
                      {componentByMonth({
                        dateStart,
                        dateEnd,
                        component: (year, month) => (
                          <td style={{ padding: 10, textAlign: 'center' }}>
                            <div>{partnerStats[year]?.[month]?.[key]?.nbProducts}</div>
                          </td>
                        ),
                      })}
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'right' }}>CA HT</td>
                      {componentByMonth({
                        dateStart,
                        dateEnd,
                        component: (year, month) => (
                          <td style={{ padding: 10, textAlign: 'center' }}>
                            <div>{Format.currency((partnerStats[year]?.[month]?.[key]?.totalHTcts ?? 0) / 100, 0)}</div>
                          </td>
                        ),
                      })}
                    </tr>
                  </>
                ))}
          </>
        </table>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`
