import { HTMLAttributes, ReactElement } from 'react'

export type TDefinitionFields<T> = {
  // Header
  label: string // Titre header de la colonne
  indexName: keyof T // Nom du champs des data, => le value dans la method format
  isSortable?: boolean // Permet de definir si une colonne est triable ou non
  sortField?: keyof T // Le nom du champs a trier dans les data car il peut etre different de l'indexName. Obligatoire si le champs est triable
  // Row  value
  format?: (values: T, isHover: boolean) => string | ReactElement // Permet de modifer la data avant affichage
  styles?: HTMLAttributes<HTMLDivElement>['style'] // Surcharger le style de l'affichage de la value
  onClick?: (values: T) => void // action lors du click sur une data
}[]

export interface ITableInfinite<T> {
  firstColumn?: {
    format?: (values: T, isRowHover: boolean) => string | ReactElement
    styles?: HTMLAttributes<HTMLDivElement>['style']
    onClick?: (values: T) => void
  }
  definitionFields: TDefinitionFields<T>
  data: (T & Required<{ id: string | number }>)[] // Le tabelau de data.  Doit contenir au moins id
  nextPage: () => void
  isQueriedAllCompleted: boolean
  onSort?: (key: keyof T, order: Order) => void
  activeSorts?: { key: string; order: Order }[]
  displayedFields?: (keyof T)[]
  setDisplayedFields?: (displayedFields: (keyof T)[]) => void
  displayError?: string
  exportFileName?: string
  getAll?: () => void
  isLoading?: boolean
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}
