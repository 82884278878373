import { FormControlLabel, TextField } from '@mui/material'
import { ReactElement } from 'react'
import { Control, Controller, FieldError, Path } from 'react-hook-form'

interface IArtisanForm<T> {
  control: Control<T>
  name: Path<T>
  label?: string
  error?: FieldError
  required?: boolean
}

export default function InputTextControl<T>({
  control,
  name,
  label,
  error,
  required = false,
}: Readonly<IArtisanForm<T>>): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          labelPlacement="top"
          required={required}
          style={{ display: 'flex', flex: '1', alignItems: 'flex-start', textAlign: 'left' }}
          control={<TextField {...field} error={!!error} helperText={error?.message ?? ''} fullWidth />}
        />
      )}
    />
  )
}
