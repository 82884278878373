import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { NavLink } from 'react-router-dom'
import { Format } from 'utils/format'
import { Theme } from 'theme/theme'
import { pages } from 'navigation/pages'
import { EPaymentProgressStatus, FactureExtended } from 'modules/facturation/store/store.facturation.interfaces'
import { Button } from '@mui/material'
import FactureMissingCreateModal from 'modules/facturation/components/FactureMissingList/FactureMissingCreate.modal'
import { useState } from 'react'
import { FactureErrorState } from '@Arti-zen/package-backoffice'

export const definitionFields = (params: {
  updatePayment?: (paymentId: string) => void
}): ITableInfinite<FactureExtended>['definitionFields'] => {
  const { updatePayment } = params
  return [
    {
      indexName: 'ref',
      label: 'Numéro de facture',
      format: ({ id, ref }) => <NavLink to={pages.connected.factureList.facture.navigate(id)}>{ref}</NavLink>,
    },
    {
      indexName: 'internalRef',
      label: 'Ref interne',
      format: ({ id, internalRef, errorState }) => {
        if (errorState === FactureErrorState.ERROR) {
          return <FactureMissing internalRef={internalRef} />
        } else {
          return <NavLink to={pages.connected.factureList.facture.navigate(id)}>{internalRef}</NavLink>
        }
      },
    },
    {
      indexName: 'clientId',
      label: 'Artisan',
      format: ({ client, clientId }) => (
        <NavLink to={pages.connected.artisan.profile.navigate(clientId)} target="_blank">
          {clientId}
          {client?.email ? ` - ${client.email}` : ''}
        </NavLink>
      ),
    },
    {
      indexName: 'createdAt',
      label: 'date facture',
      format: ({ createdAt }) => Format.dateFr(createdAt),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'amount',
      label: 'Montant HT',
      format: ({ amount }) => Format.currency(amount, 2),
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'errorState',
      label: 'error state',
      onClick: ({ pdfLink }) => window.open(pdfLink),
    },
    {
      indexName: 'paymentProgress',
      label: 'Payée',
      format: ({ paymentProgress }) => <DisplayPaymentProgress paymentProgress={paymentProgress} />,
      onClick: ({ id, paymentProgress }) => {
        if (!updatePayment) return null

        if (paymentProgress !== EPaymentProgressStatus.unknown) {
          updatePayment(id)
        } else {
          window.alert('Payment not managed yet for subscription')
        }
      },
    },
  ]
}

export const DisplayPaymentProgress = ({ paymentProgress }: { paymentProgress: EPaymentProgressStatus }) => {
  const colors = {
    [EPaymentProgressStatus.inProgress]: Theme.colors.blueArtizen,
    [EPaymentProgressStatus.paid]: Theme.colors.greenArtizen,
    [EPaymentProgressStatus.unpaid]: 'red',
    [EPaymentProgressStatus.unknown]: 'white',
    [EPaymentProgressStatus.partialLess]: 'orange',
    [EPaymentProgressStatus.partialSup]: 'yellow',
  }

  const fontColors = {
    [EPaymentProgressStatus.inProgress]: 'white',
    [EPaymentProgressStatus.paid]: Theme.colors.blueArtizen,
    [EPaymentProgressStatus.unpaid]: 'white',
    [EPaymentProgressStatus.unknown]: 'black',
    [EPaymentProgressStatus.partialLess]: 'white',
    [EPaymentProgressStatus.partialSup]: Theme.colors.blueArtizen,
  }
  return (
    <div
      style={{
        display: 'flex',
        width: 100,
        padding: 10,
        backgroundColor: colors[paymentProgress],
        color: fontColors[paymentProgress],
        borderRadius: 5,
        justifyContent: 'center',
      }}
    >
      {paymentProgress}
    </div>
  )
}

const FactureMissing = ({ internalRef }: { internalRef: string }) => {
  const [clientId, year, numero] = internalRef.split('_')
  const [open, setOpen] = useState(false)

  return (
    <div>
      <Button onClick={() => setOpen(true)}>{internalRef}</Button>
      <FactureMissingCreateModal
        clientId={+clientId}
        year={+year}
        numero={+numero}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  )
}
