import { Facture } from '@Arti-zen/package-backoffice'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { pages } from 'navigation/pages'
import { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Theme } from 'theme/theme'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { Format } from 'utils/format'

type TFactureMissingCreateModal = {
  clientId: number
  year: number
  numero: number
  open: boolean
  onClose: () => void
}

export default function FactureMissingCreateModal({
  clientId,
  year,
  numero,
  open,
  onClose,
}: Readonly<TFactureMissingCreateModal>): ReactElement {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>(null)
  const [newFactureInfo, setNewFactureInfo] = useState<Facture>(null)

  useEffect(() => {
    open && setErrorMessage(null)
  }, [open])

  const genereateFactureInfo = async () => {
    try {
      const newFactureInfo = await ApiArtizenBO.facturation.createFacture({ clientId, year, numero, generate: false })
      setNewFactureInfo(newFactureInfo)
    } catch (err) {
      setErrorMessage(err?.response?.data?.message ?? 'Erreur inconnue')
    }
  }

  const genereateFacture = async () => {
    try {
      const newFactureInfo = await ApiArtizenBO.facturation.createFacture({ clientId, year, numero, generate: true })
      onClose()
      navigate(pages.connected.factureList.facture.navigate(newFactureInfo.id))
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen={false}>
      <DialogTitle
        style={{
          textAlign: 'center',
          backgroundColor: errorMessage ? 'red' : Theme.colors.blueArtizen,
          color: 'white',
        }}
      >
        {errorMessage ? 'Erreur' : 'Création de facture'}
      </DialogTitle>

      <DialogContent
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: 20,
          padding: 20,
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        {errorMessage ? <>{errorMessage}</> : <></>}

        {!newFactureInfo && !errorMessage ? (
          <>
            Voulez-vous créer la facture numéro {numero} pour l'année {year} du client {clientId}?
            <Button
              style={{ backgroundColor: Theme.colors.greenArtizen, color: 'white', width: '50%' }}
              onClick={genereateFactureInfo}
              size="large"
              variant="contained"
            >
              Oui
            </Button>
          </>
        ) : (
          <></>
        )}

        {newFactureInfo ? (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 20 }}>
            <div style={{ display: 'flex', flex: 1, paddingBottom: 40 }}>
              Num facture : {newFactureInfo.internalRef}
            </div>

            <DetailFact label="Abonnement" amountCts={newFactureInfo.detailFull.detail.mainSubscription} />
            <DetailFact label="Sous-compte" amountCts={newFactureInfo.detailFull.detail.subAccounts} />
            <DetailFact label="Option paiement" amountCts={newFactureInfo.detailFull.detail.options} />
            <DetailFact label="Total Brute HT" amountCts={newFactureInfo.detailFull.totalFixeBruteHT} isSubTotal />
            <DetailFact label="Promo HT" amountCts={newFactureInfo.detailFull.totalPromoHT} />
            <DetailFact label="Paiements" amountCts={newFactureInfo.detailFull.totalVarHT} />
            <DetailFact label="Total HT" amountCts={newFactureInfo.detailFull.totalHT} isSubTotal />
            <DetailFact label="Total TVA" amountCts={newFactureInfo.detailFull.totalTVA} />
            <DetailFact label="Total TTC" amountCts={newFactureInfo.detailFull.totalTTC} isSubTotal />
            <Button onClick={genereateFacture}>Créer</Button>
          </div>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  )
}

const DetailFact = ({
  label,
  amountCts,
  isSubTotal = false,
}: {
  label: string
  amountCts: number | string
  isSubTotal?: boolean
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        fontWeight: isSubTotal ? 600 : 400,
        borderTop: isSubTotal ? 'solid 1px' : '',
      }}
    >
      <div style={{ display: 'flex', flex: 3 }}>{label}</div>
      <div style={{ display: 'flex', flex: 1 }}>{Format.currency(Number(amountCts) / 100, 2)}</div>
    </div>
  )
}
