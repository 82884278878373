import { ReactElement, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order } from 'components/table/infinite/InfiniteTable.interfaces'
import { Format } from 'utils/format'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { factFulfilled } from 'utils/stats'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { artisanListActions } from 'modules/artisanList/store/store.artisanList'
import CsvDownloadButton from 'react-json-to-csv'
import { definitionFields, TFactureFulFilledExtended } from './dashboardMrr.definitionFields'
import { dashboardActions } from 'modules/dashboard/store/store.dashboard'
import SelectBase from 'components/forms/Select.base'

type TStats = {
  totalMainHtCts: number
  totalSubHtCts: number
  totalPromoHtCts: number
  totalOptionsHtCts: number
  totalHtCts: number
  totalVariableHtCts: number
}

export default function DashboardMrr(): ReactElement {
  const dispatch = useAppDispatch()

  console.log('test 7')

  const { artisanListBase } = useAppSelector((state) => state.artisanList)
  const { userLinkedList } = useAppSelector((state) => state.dashboard)

  const [dateStart, setDateStart] = useState<DateTime>(
    DateTime.now().setZone('Europe/Paris').minus({ month: 1 }).startOf('day')
  )
  const [dateEnd, setDateEnd] = useState<DateTime>(DateTime.now().setZone('Europe/Paris').endOf('day'))
  const [factures, setFactures] = useState<TFactureFulFilledExtended[]>([])
  const [partnerSelection, setPartnerSelection] = useState<'RICHARDSON' | 'NO RICHARSON' | 'ALL'>('ALL')
  const [stats, setStats] = useState<TStats>({
    totalMainHtCts: 0,
    totalPromoHtCts: 0,
    totalSubHtCts: 0,
    totalOptionsHtCts: 0,
    totalHtCts: 0,
    totalVariableHtCts: 0,
  })

  const fields = definitionFields()

  const onSort = () => console.log('sort')

  const [displayedFields, setDisplayedFields] = useState<(keyof TFactureFulFilledExtended)[]>(
    fields.map((def) => def.indexName)
  )
  const activeSort = [{ key: 'createdAt', order: Order.DESC }]

  const richardsonUserIdList = useMemo(
    () => userLinkedList.filter((user) => user.source === 'RICHARDSON').map((user) => user.id),
    [userLinkedList]
  )

  const companySelectedList = useMemo(() => {
    switch (partnerSelection) {
      case 'RICHARDSON':
        return artisanListBase.filter((artisan) => richardsonUserIdList.includes(artisan.id))

      case 'NO RICHARSON':
        return artisanListBase.filter((artisan) => !richardsonUserIdList.includes(artisan.id))

      case 'ALL':
        return artisanListBase
    }
  }, [richardsonUserIdList, artisanListBase, partnerSelection])

  const factureListFiltered = useMemo(() => {
    return factures.filter((fact) => {
      return companySelectedList.find((company) => +company.id === +fact.clientId)
    })
  }, [companySelectedList, factures])

  useEffect(() => {
    dispatch(artisanListActions.getAll())
    if (!userLinkedList || userLinkedList.length === 0) dispatch(dashboardActions.getPartnerStats())
  }, [])

  useEffect(() => {
    let isMount = true

    ApiArtizenBO.facturation
      .getAll({
        dateStart: dateStart.toString(),
        dateEnd: dateEnd.toString(),
      })
      .then((factureList) => {
        const factures = factureList.map((fact) => {
          return {
            ...factFulfilled(fact),
            artisan: artisanListBase.find((arti) => +arti.id === +fact.clientId),
          }
        })

        isMount && setFactures(factures)
      })

    return () => {
      isMount = false
    }
  }, [dateStart, dateEnd])

  useEffect(() => {
    setStats(
      factureListFiltered.reduce(
        (totaux, fact): TStats => {
          return {
            totalMainHtCts: totaux.totalMainHtCts + fact.mainHtCts,
            totalSubHtCts: totaux.totalSubHtCts + fact.subHtCts,
            totalPromoHtCts: totaux.totalPromoHtCts + fact.promoHtCts,
            totalOptionsHtCts: totaux.totalOptionsHtCts + fact.optionsHtCts,
            totalHtCts: totaux.totalHtCts + fact.totalHtCts,
            totalVariableHtCts: totaux.totalVariableHtCts + fact.variableHtCts,
          }
        },
        {
          totalMainHtCts: 0,
          totalPromoHtCts: 0,
          totalSubHtCts: 0,
          totalOptionsHtCts: 0,
          totalHtCts: 0,
          totalVariableHtCts: 0,
        }
      )
    )
  }, [factureListFiltered])

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 50, marginTop: 50 }}>
        <DatePicker label="Date de début" value={dateStart} onChange={setDateStart} timezone="Europe/Paris" />

        <DatePicker label="Date de fin" value={dateEnd} onChange={setDateEnd} />

        <SelectBase
          label="Partenaire"
          items={[
            { value: 'ALL', label: 'Tous' },
            { value: 'RICHARDSON', label: 'Richardson' },
            { value: 'NO RICHARSON', label: 'Non Richardson' },
          ]}
          onChange={setPartnerSelection}
          defaultValue={'ALL'}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
        <DisplayStat name="Abonnements HT" value={Format.currency(stats.totalMainHtCts / 100, 2)} />
        <DisplayStat name="Comptes Salariés HT" value={Format.currency(stats.totalSubHtCts / 100, 2)} />
        <DisplayStat name="Options HT" value={Format.currency(stats.totalOptionsHtCts / 100, 2)} />
        <DisplayStat
          isTotal
          name="Total HT Brut"
          value={Format.currency((stats.totalMainHtCts + stats.totalSubHtCts + stats.totalOptionsHtCts) / 100, 2)}
        />
        <DisplayStat name="Promos HT" value={Format.currency(-stats.totalPromoHtCts / 100, 2)} />
        <DisplayStat
          isTotal
          name="Total HT Net (MRR)"
          value={Format.currency(
            (stats.totalMainHtCts + stats.totalSubHtCts + stats.totalOptionsHtCts - stats.totalPromoHtCts) / 100,
            2
          )}
        />
        <DisplayStat name="Variable HT" value={Format.currency(stats.totalVariableHtCts / 100, 2)} />
        <DisplayStat isTotal name="Total HT Net" value={Format.currency(stats.totalHtCts / 100, 2)} />
        <DisplayStat name="Nombre factures" value={String(factureListFiltered.length)} />
        <DisplayStat
          name="Facture HT moyenne"
          value={Format.currency(stats.totalHtCts / 100 / factureListFiltered.length, 2)}
        />
      </div>

      <div>
        <CsvDownloadButton data={factureListFiltered} filename="factures" style={{ margin: 5 }}>
          Télécharger les factures
        </CsvDownloadButton>
      </div>

      <WrapperContent>
        <TableInfinite<TFactureFulFilledExtended>
          displayedFields={displayedFields}
          setDisplayedFields={setDisplayedFields}
          definitionFields={fields}
          onSort={onSort}
          activeSorts={activeSort}
          data={factureListFiltered}
          nextPage={() => {}}
          isQueriedAllCompleted={true}
          firstColumn={{
            format: () => <></>,
          }}
        />
      </WrapperContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`

const DisplayStat = ({ name, value, isTotal = false }: { name: string; value: string; isTotal?: boolean }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        ...(isTotal ? { fontWeight: 600, borderTop: 'solid 1px' } : {}),
      }}
    >
      <div style={{ display: 'flex', flex: 3 }}>{name}</div>
      <div style={{ display: 'flex', flex: 1 }}>{value}</div>
    </div>
  )
}
