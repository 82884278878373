import { useAppSelector } from 'hooks/useStore'
import { ReactElement } from 'react'
import { Theme } from 'theme/theme'
import { componentByMonth } from './dashboardFigures.helpers'
import DownloadButton from 'components/DownloadButton'

export default function DashboardQuotationsFigures(): ReactElement {
  const { dateStart, dateEnd, quotationStats, quotations } = useAppSelector((state) => state.dashboard)

  return (
    <>
      <td
        style={{
          fontWeight: 600,
          color: Theme.colors.blueArtizen,
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
        }}
      >
        Devis <DownloadButton data={quotations} fileName="quotations" label={null} size="small" />
      </td>
      {!quotationStats ? null : (
        <>
          <tr>
            <td style={{ textAlign: 'right' }}>Nombre</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{quotationStats[year]?.[month]?.count}</div>
                </td>
              ),
            })}
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>User Unique</td>
            {componentByMonth({
              dateStart,
              dateEnd,
              component: (year, month) => (
                <td style={{ padding: 10, textAlign: 'center' }}>
                  <div>{quotationStats[year]?.[month]?.uniqueUserList.length}</div>
                </td>
              ),
            })}
          </tr>
        </>
      )}
    </>
  )
}
