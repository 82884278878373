import { Input } from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import { ReactElement, useMemo, useState } from 'react'
import SearchInputSync from './SearchInputSync'

interface IInputSelect<T> {
  onSelect: (item: T) => void
  options: T[]
  searchField: keyof T
  nbMaxDisplay?: number
}

export default function InputSelect<T>({
  onSelect,
  options,
  searchField,
  nbMaxDisplay = 20,
}: IInputSelect<T>): ReactElement {
  const [searchText, setSearchText] = useState<string>()

  const search = useDebounce(searchText, 200)

  const optionList = useMemo(() => {
    if (!search) return options.slice(0, nbMaxDisplay)
    return options
      .filter((option) => (option[searchField] as string).toLowerCase().includes(search.toLowerCase()))
      .slice(0, nbMaxDisplay)
  }, [options, search])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '550px', minWidth: '500px' }}>
      <SearchInputSync placeholder={'Tapez une recherche'} onChange={setSearchText} />
      {/* <Input onChange={(e) => setSearchText(e.target.value)} style={{ width: '100%', marginBottom: 10 }} /> */}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {optionList.map((option) => (
          <div
            //@ts-expect-error no comment
            key={option.id}
            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
            onClick={() => onSelect(option)}
          >
            {option[searchField] as String}
          </div>
        ))}
      </div>
    </div>
  )
}
