import { TCodePromo, TCodePromoUser, UserArtizen } from '@Arti-zen/package-backoffice'
import { Button } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Theme } from 'theme/theme'
import InputDateControl from 'components/forms/InputDate.control'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { Format } from 'utils/format'
import CodePromoCreateForm from './CodePromoCreate.form'
import { DateTime } from 'luxon'
import { StatusAlertService } from 'react-status-alert'
import InputSelect from 'components/forms/InputSelect'

interface IArtisanForm {
  artisanId: UserArtizen['id']
  onSubmit: (data: TCodePromoUser) => void
}

type TCodePromoSelect = TCodePromo & { label: string }

const CodePromoSelect = (codePromo: TCodePromo): TCodePromoSelect => {
  const { code, duration, absoluteDiscount, relativeDiscount } = codePromo
  return {
    ...codePromo,
    label: `${code} - ${codePromo.duration} mois - ${absoluteDiscount ? Format.currency(absoluteDiscount) : relativeDiscount + '%'}`,
  }
}

export default function ArtisanCodePromoForm({ artisanId, onSubmit }: Readonly<IArtisanForm>): ReactElement {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<TCodePromoUser>({
    reValidateMode: 'onChange',
  })

  const promoCode = watch('promoCode')
  const promoStart = watch('promoStart')

  const [codePromoList, setCodePromoList] = useState<TCodePromoSelect[]>([])
  const [codePromoSelected, setCodePromoSelected] = useState<TCodePromo | null>(null)
  const [displayMode, setDisplayMode] = useState<'creation' | 'selection' | null>(null)

  useEffect(() => {
    let isMount = true
    ApiArtizenBO.codePromo
      .getAll()
      .then(
        (codePromoList) =>
          isMount &&
          setCodePromoList(
            codePromoList
              .filter((codePromo) => codePromo.isValid && (codePromo.relativeDiscount || codePromo.absoluteDiscount))
              .map((codePromo) => CodePromoSelect(codePromo))
          )
      )

    return () => {
      isMount = false
    }
  }, [])

  useEffect(() => {
    const startDateTime = promoStart ? DateTime.fromISO(promoStart) : DateTime.now()
    const promoEnd = startDateTime
      .plus({ months: codePromoSelected?.duration ?? 12 })
      .minus({ days: 1 })
      .toISODate()

    setValue('promoCode', codePromoSelected)
    setValue('promoCodeId', codePromoSelected?.id)
    setValue('promoStart', startDateTime.toISODate())
    setValue('promoEnd', promoEnd)
  }, [codePromoSelected])

  const onValidated = async (data: TCodePromoUser) => {
    try {
      const codePromoUser: TCodePromoUser = await ApiArtizenBO.codePromo.addCodePromoToUser({
        codePromoIdToLink: +data.promoCodeId,
        userId: artisanId,
        startDate: data.promoStart,
        endDate: data.promoEnd,
      })
      StatusAlertService.showSuccess('Code Promo ajouté')
      onSubmit(codePromoUser)
    } catch (error) {
      StatusAlertService.showError(error?.message)
    }
  }

  const PromoCodeInfo = useCallback(() => {
    return promoCode ? <DisplayPromoCodeInfo promoCode={promoCode} /> : <></>
  }, [promoCode])

  return (
    <>
      <div style={{ display: displayMode === 'creation' ? 'flex' : 'none' }}>
        <CodePromoCreateForm
          onCreated={(codePromo) => {
            setCodePromoList([CodePromoSelect(codePromo), ...codePromoList])
            setCodePromoSelected(codePromo)
            setDisplayMode(null)
          }}
        />
      </div>

      <div style={{ display: displayMode === 'selection' ? 'flex' : 'none' }}>
        <InputSelect
          options={codePromoList}
          searchField="label"
          onSelect={(codePromo) => {
            setCodePromoSelected(codePromo)
            setDisplayMode(null)
          }}
        />
      </div>

      <form
        onSubmit={handleSubmit(onValidated, console.error)}
        style={{ display: displayMode ? 'none' : 'flex', width: '100%', flexDirection: 'column', gap: 20 }}
      >
        <Row>
          <Button
            onClick={() => setDisplayMode('selection')}
            style={{
              display: 'flex',
              alignSelf: 'center',
              backgroundColor: Theme.colors.blueArtizen,
              color: 'white',
              width: 200,
            }}
          >
            Chercher
          </Button>
          <Button
            onClick={() => setDisplayMode('creation')}
            style={{
              display: 'flex',
              alignSelf: 'center',
              backgroundColor: Theme.colors.greenArtizen,
              color: 'white',
              width: 200,
            }}
          >
            Créer
          </Button>
        </Row>
        <Row>
          <PromoCodeInfo />
        </Row>
        <Row>
          <InputDateControl control={control} name="promoStart" label="Date de début" error={errors.promoStart} />
          <InputDateControl control={control} name="promoEnd" label="Date de fin" error={errors.promoEnd} />
        </Row>

        <Button
          type="submit"
          style={{
            display: 'flex',
            alignSelf: 'center',
            backgroundColor: Theme.colors.blueArtizen,
            color: 'white',
            width: 200,
          }}
        >
          Valider
        </Button>
      </form>
    </>
  )
}

const DisplayPromoCodeInfo = ({ promoCode }: { promoCode: TCodePromo }): ReactElement => {
  return (
    <Row>
      <div>Code : {promoCode.code}</div>
      <div>
        Valeur:{' '}
        {promoCode.absoluteDiscount ? Format.currency(promoCode.absoluteDiscount) : promoCode.relativeDiscount + '%'}
      </div>
      <div>Durée conseillée : {promoCode.duration} mois</div>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`
