import { Order } from '@Arti-zen/package-backoffice'
import { TData } from './companyLastDocList.definitionFields'

export const sortData = (key: keyof TData, order: Order, dataSortable: TData[]) => {
  if (key === 'lastCall') {
    if (order === Order.ASC) {
      return dataSortable.sort((a, b) =>
        new Date(a.lastCall?.createdAt ?? 0).valueOf() > new Date(b.lastCall?.createdAt ?? 0).valueOf() ? 1 : -1
      )
    } else {
      return dataSortable.sort((a, b) =>
        new Date(a.lastCall?.createdAt ?? 0).valueOf() < new Date(b.lastCall?.createdAt ?? 0).valueOf() ? 1 : -1
      )
    }
  } else {
    if (order === Order.ASC) {
      return dataSortable.sort((a, b) => (a[key] > b[key] ? 1 : -1))
    } else {
      return dataSortable.sort((a, b) => (a[key] < b[key] ? 1 : -1))
    }
  }
}
