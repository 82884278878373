import { TextField } from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import { statusByMenu } from 'modules/invoiceList/components/InvoiceList.interfaces'
import InvoiceListTable from 'modules/invoiceList/components/InvoiceListTable/InvoiceListTable'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'

export default function ArtisanInvoiceList(): ReactElement {
  const [search, setSearch] = useState<string>()

  const textSearched = useDebounce(search, 500)

  return (
    <Wrapper>
      <TextField
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Rechercher une facture"
        style={{ display: 'flex', width: '50%' }}
      />

      <InvoiceListTable search={textSearched} filterBase={{ status: statusByMenu.all }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 50px;
`
