import moment from 'moment'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { ClientClass, QuotationClass, Utils } from '@Arti-zen/package-backoffice'
import { NavigateFunction } from 'react-router-dom'
import { pages } from 'navigation/pages'

export const definitionFields = (
  navigate: NavigateFunction
): ITableInfinite<QuotationClass['meilisearchDoc']>['definitionFields'] => {
  return [
    {
      indexName: 'ref',
      label: 'numéro',
      isSortable: true,
      sortField: 'number',
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
    {
      indexName: 'title',
      label: 'titre',
      isSortable: true,
      sortField: 'title',
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
    {
      indexName: 'client',
      label: 'client intervention',
      sortField: 'clientInterventionName',
      isSortable: true,
      format: ({ client }) => (client ? new ClientClass(client).displayFullName : ''),
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
    {
      indexName: 'billingClient',
      label: 'client facturation',
      sortField: 'clientBillingName',
      isSortable: true,
      format: ({ billingClient }) => (billingClient ? new ClientClass(billingClient).displayFullName : ''),
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
    {
      indexName: 'creationDate',
      label: 'date',
      format: ({ creationDate }) => moment(creationDate).format('DD/MM/YYYY'),
      isSortable: true,
      sortField: 'creationDate',
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
    {
      indexName: 'totalTTCDisplay',
      label: 'total ttc',
      format: ({ totalTTC_cts }) => Utils.displayFormatter.printAmount(totalTTC_cts / 100) + ' €',
      styles: { textAlign: 'right' },
      isSortable: true,
      sortField: 'totalTTC_cts',
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
    {
      indexName: 'status',
      label: 'statut',
      // format: ({ status, id }) => <QuotationListTableStatus status={status} id={id} />,
      styles: { overflow: 'visible' },
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
    {
      indexName: 'emailsSent',
      label: 'dernier envoi par  mail',
      format: ({ emailsSent }) =>
        emailsSent?.length > 0
          ? emailsSent
              .map((email) => moment(email.createdAt))
              .sort((a, b) => b.diff(a))[0]
              .format('DD/MM/YYYY')
          : '',
      onClick: ({ id }) => {
        navigate(pages.connected.quotation.root.navigate(id))
      },
    },
  ]
}

export const firstColumn: ITableInfinite<QuotationClass['meilisearchDoc']>['firstColumn'] = {
  format: (quotation, isRowHover) => <></>,
}
