import { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ITableInfinite } from '../InfiniteTable.interfaces'
import { useInfiniteTableContext } from '../InfiniteTable.context'
import { Theme } from 'theme/theme'

export default function TableContent<T>(): ReactElement {
  const {
    definitionFields,
    firstColumn,
    displayedFields,
    data,
    nextPage,
    isQueriedAllCompleted = false,
    displayError,
  } = useInfiniteTableContext<T>()
  const [wrapperRefHeight, setWrapperRefHeight] = useState(75)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapperRef?.current?.clientHeight) {
      setWrapperRefHeight(wrapperRef?.current?.clientHeight)
    }
  }, [wrapperRef?.current?.clientHeight, data.length])

  const isError = displayError || data.length === 0

  return (
    <div
      ref={wrapperRef}
      style={{ display: isError ? 'none' : 'flex', flexDirection: 'column', flex: 1, width: '100%', height: '100%' }}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={nextPage}
        height={wrapperRefHeight}
        hasMore={!isQueriedAllCompleted}
        loader={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Loader type="BallTriangle" color="#092ca7" height={50} width={50} /> */}
            Chargement des donnees...
          </div>
        }
      >
        <div>
          {data.map((val, idx) => {
            return (
              <TableRow
                key={val.id}
                idx={idx}
                data={val}
                firstColumn={firstColumn ?? {}}
                displayedFields={displayedFields}
                definitionFields={definitionFields}
              />
            )
          })}
        </div>
        {isQueriedAllCompleted ? <div style={{ height: 60 }} /> : null}
      </InfiniteScroll>
    </div>
  )
}

const TableRow = <T,>({
  idx,
  data,
  firstColumn,
  displayedFields,
  definitionFields,
}: {
  idx: number
  data: T & Required<{ id: string | number }>
  firstColumn?: ITableInfinite<T>['firstColumn']
  displayedFields: ITableInfinite<T>['displayedFields']
  definitionFields: ITableInfinite<T>['definitionFields']
}): ReactElement => {
  const [isHover, setIsHover] = useState(false)

  return (
    <Row isHover={isHover} idx={idx} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <FirstColumn onClick={() => (firstColumn?.onClick ? firstColumn.onClick(data) : null)}>
        {firstColumn?.format ? firstColumn?.format(data, isHover) : ''}
      </FirstColumn>
      {displayedFields.map((indexName): ReactElement => {
        const { format, styles, onClick } = definitionFields.find((field) => field.indexName === indexName)
        return (
          <ButtonData
            key={`${String(indexName)}_${data.id}`}
            isClickable={onClick}
            onClick={() => (onClick ? onClick(data) : null)}
          >
            <DisplayedData isHover={isHover} style={{ ...styles }}>
              {format ? format(data, isHover) : data[String(indexName)]}
            </DisplayedData>
          </ButtonData>
        )
      })}
    </Row>
  )
}

const Row = styled.div<{ idx: number; isHover: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  border: none;
  height: 60px;
  background-color: ${(props) => (props.idx % 2 !== 0 ? 'white' : 'rgba(223, 228, 248, 0.6)')};
  ${(props) => (props.isHover ? 'background-color: rgba(10, 48, 175, 0.3);font-weight: 600;' : '')};
  font-family: ${Theme.font.family};
`

const ButtonData = styled.button<{ isClickable?: Function }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  color: inherit;
  font-weight: inherit;
`

const DisplayedData = styled.div<{ isHover: boolean }>`
  ${Theme.font.size.normal};
  font-family: ${Theme.font.family};
  color: inherit;
  font-weight: inherit;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  ${({ isHover }) => (isHover ? 'transform: scale(1.1);z-index: 200;' : '')};
`

const FirstColumn = styled(ButtonData)`
  width: 40px;
  flex: initial;
`
