import { TCompanyLastCall, TCompanyLastDocument, Utils } from '@Arti-zen/package-backoffice'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { pages } from 'navigation/pages'
import { Link } from 'react-router-dom'
import { Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { Theme } from 'theme/theme'

export type TData = TCompanyLastDocument & { lastCall: TCompanyLastCall; allCall: TCompanyLastCall[] }

export const definitionFields = (setSelectedData: (data: TData) => void): ITableInfinite<TData>['definitionFields'] => {
  return [
    {
      indexName: 'companyId',
      label: 'Artisan Id',
      isSortable: true,
      sortField: 'companyId',
      format: ({ companyId }) => (
        <Link to={pages.connected.artisan.profile.navigate(companyId)} target="_blank">
          {companyId}
        </Link>
      ),
    },
    {
      indexName: 'companyName',
      label: 'Nom entreprise',
      isSortable: true,
      sortField: 'companyName',
      format: ({ companyId, companyName }) => (
        <Link to={pages.connected.artisan.profile.navigate(companyId)} target="_blank">
          {companyName}
        </Link>
      ),
    },
    {
      indexName: 'companyEmail',
      label: 'Email entreprise',
      isSortable: true,
      sortField: 'companyEmail',
      format: ({ companyEmail, companyId }) => (
        <Link to={pages.connected.artisan.profile.navigate(companyId)} target="_blank">
          {companyEmail}
        </Link>
      ),
    },
    {
      indexName: 'documentLastDate',
      label: 'Date dernière action',
      isSortable: true,
      sortField: 'documentLastDate',
      format: (data) => <DisplayLastDoc {...data} />,
    },
    {
      indexName: 'lastCall',
      label: 'Date dernier appel',
      isSortable: true,
      sortField: 'lastCall',
      format: ({ lastCall, allCall }) => <DisplayLastCall lastCall={lastCall} allCall={allCall} />,
      onClick: setSelectedData,
    },
  ]
}

const DisplayLastCall = ({ lastCall, allCall }: { lastCall: TCompanyLastCall; allCall: TCompanyLastCall[] }) => {
  if (!lastCall) return <AddCircle style={{ color: Theme.colors.blueArtizen }} />

  return (
    <HtmlTooltip
      arrow
      placement="left"
      title={
        <>
          {allCall.map((call) => (
            <div style={{ fontSize: 14 }}>{Utils.time.classicTimeDisplay(call.createdAt)}</div>
          ))}
        </>
      }
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <Button
        style={{
          backgroundColor: 'transparent',
          color: 'black',
          fontSize: 14,
          fontFamily: 'inherit',
        }}
      >
        {lastCall?.createdAt ? Utils.time.classicTimeDisplay(lastCall?.createdAt) : ''}
      </Button>
    </HtmlTooltip>
  )
}

const DisplayLastDoc = ({ documentLastDate, interventionLastDate, quotationLastDate, invoiceLastDate }: TData) => {
  return (
    <HtmlTooltip
      arrow
      placement="left"
      title={
        <>
          <div style={{ fontSize: 14 }}>
            Intervention : {interventionLastDate ? Utils.time.classicTimeDisplay(interventionLastDate) : ''}
          </div>
          <div style={{ fontSize: 14 }}>
            Devis : {quotationLastDate ? Utils.time.classicTimeDisplay(quotationLastDate) : ''}
          </div>
          <div style={{ fontSize: 14 }}>
            Facture : {invoiceLastDate ? Utils.time.classicTimeDisplay(invoiceLastDate) : ''}
          </div>
        </>
      }
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <Button
        style={{
          backgroundColor: 'transparent',
          color: 'black',
          fontSize: 14,
          fontFamily: 'inherit',
        }}
      >
        {documentLastDate ? Utils.time.classicTimeDisplay(documentLastDate) : ''}
      </Button>
    </HtmlTooltip>
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    border: '1px solid #dadde9',
  },
}))
