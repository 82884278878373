import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { IArtisanState } from './store.artisan.interfaces'
import { IInterventionsGet, IInvoicesGet, IQuotationsGet, Order, UserArtizen } from '@Arti-zen/package-backoffice'
import { RootState } from 'store/store'
import { factureExtended } from 'modules/facturation/store/store.facturation.helpers'

const get = {
  action: createAsyncThunk('artisan/get', async (id: UserArtizen['id']) => {
    return Promise.all([ApiArtizenBO.artizenUsers.getOne(id), ApiArtizenBO.artizenUsers.getOneChildren(id)])
  }),
  reducer: (builder: ActionReducerMapBuilder<IArtisanState>) => {
    builder
      .addCase(get.action.pending, (state) => {
        state.isLoading = true
      })
      .addCase(get.action.fulfilled, (state, action) => {
        const [artisan, children] = action.payload
        state.artisan = artisan
        state.children = children
        state.companyIds = [artisan.id, ...children.map((child) => child.id)]
        state.isLoading = false
      })
      .addCase(get.action.rejected, (state, _action) => {
        state.artisan = null
        state.children = []
        state.companyIds = []
        state.isLoading = false
      })
  },
}

const patch = {
  action: createAsyncThunk(
    'artisan/patch',
    async ({ id, changes }: { id: UserArtizen['id']; changes: Partial<UserArtizen> }) => {
      return ApiArtizenBO.artizenUsers.patch(id, changes)
    }
  ),
  reducer: (builder: ActionReducerMapBuilder<IArtisanState>) => {
    builder
      .addCase(patch.action.pending, (state) => {
        state.isLoading = true
      })
      .addCase(patch.action.fulfilled, (state, action) => {
        state.artisan = action.payload
        state.isLoading = false
      })
      .addCase(patch.action.rejected, (state, _action) => {
        state.isLoading = false
      })
  },
}

const fetchInterventions = {
  action: createAsyncThunk('artisan/interventions', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const {
      artisan: { dashboard, companyIds },
    } = state

    const newFilters: IInterventionsGet = {
      userIds: companyIds,
      page: 0,
      limit: 999,
      ...(dashboard.dateStart ? { after: dashboard.dateStart.toJSDate() } : {}),
      ...(dashboard.dateEnd ? { before: dashboard.dateEnd.toJSDate() } : {}),
      sort: [
        { key: 'interventionStartDate', order: Order.DESC },
        { key: 'id', order: Order.DESC },
      ],
    }

    return ApiArtizenBO.interventions.get(newFilters)
  }),
  reducer: (builder: ActionReducerMapBuilder<IArtisanState>) => {
    builder
      .addCase(fetchInterventions.action.pending, (state, action) => {})
      .addCase(fetchInterventions.action.fulfilled, (state, action) => {
        state.dashboard.interventions = action.payload
      })
      .addCase(fetchInterventions.action.rejected, (state, _action) => {
        state.dashboard.interventions = []
      })
  },
}

const fetchQuotations = {
  action: createAsyncThunk('artisan/quotations', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const {
      artisan: { dashboard, companyIds },
    } = state

    const newFilters: IQuotationsGet = {
      userIds: companyIds,
      page: 0,
      limit: 999,
      ...(dashboard.dateStart ? { startDate: dashboard.dateStart.toJSDate() } : {}),
      ...(dashboard.dateEnd ? { endDate: dashboard.dateEnd.toJSDate() } : {}),
      sort: [{ key: 'id', order: Order.DESC }],
    }

    return ApiArtizenBO.quotations.get(newFilters)
  }),
  reducer: (builder: ActionReducerMapBuilder<IArtisanState>) => {
    builder
      .addCase(fetchQuotations.action.pending, (state, action) => {})
      .addCase(fetchQuotations.action.fulfilled, (state, action) => {
        state.dashboard.quotations = action.payload
      })
      .addCase(fetchQuotations.action.rejected, (state, _action) => {
        state.dashboard.quotations = []
      })
  },
}

const fetchFactures = {
  action: createAsyncThunk('artisan/factureList', async (id: UserArtizen['id']) => {
    return ApiArtizenBO.facturation.getAll({
      clientId: id,
    })
  }),
  reducer: (builder: ActionReducerMapBuilder<IArtisanState>) => {
    builder
      .addCase(fetchFactures.action.pending, (state, _action) => {})
      .addCase(fetchFactures.action.fulfilled, (state, action) => {
        const factureExtendedList = action.payload.map((fact) => factureExtended(fact))
        state.factureList = factureExtendedList
      })
      .addCase(fetchFactures.action.rejected, (state, _action) => {
        state.factureList = []
      })
  },
}

const fetchInvoices = {
  action: createAsyncThunk('artisan/invoices', async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const {
      artisan: { dashboard, companyIds },
    } = state

    const newFilters: IInvoicesGet = {
      userIds: companyIds,
      page: 0,
      limit: 999,
      ...(dashboard.dateStart ? { startDate: dashboard.dateStart.toJSDate() } : {}),
      ...(dashboard.dateEnd ? { endDate: dashboard.dateEnd.toJSDate() } : {}),
      sort: [{ key: 'id', order: Order.DESC }],
    }

    return ApiArtizenBO.invoices.get(newFilters)
  }),
  reducer: (builder: ActionReducerMapBuilder<IArtisanState>) => {
    builder
      .addCase(fetchInvoices.action.pending, (state, action) => {})
      .addCase(fetchInvoices.action.fulfilled, (state, action) => {
        state.dashboard.invoices = action.payload
      })
      .addCase(fetchInvoices.action.rejected, (state, _action) => {
        state.dashboard.invoices = []
      })
  },
}

const getPartnerStatsCompany = {
  action: createAsyncThunk('dashboard/getPartnerStats', async (companyIds: UserArtizen['id'][], thunkApi) => {
    const state = thunkApi.getState() as RootState
    const { dateStart, dateEnd } = state.dashboard
    return ApiArtizenBO.partnerProducts.stats({
      dateStart: dateStart?.toFormat('yyyy/MM/dd'),
      dateEnd: dateEnd?.toFormat('yyyy/MM/dd'),
      userIds: companyIds.map((id) => String(id)),
    })
  }),
  reducer: (builder: ActionReducerMapBuilder<IArtisanState>) => {
    builder
      .addCase(getPartnerStatsCompany.action.pending, (state, action) => {
        state.dashboard.partnerStatList = []
        state.dashboard.userLinkedList = []
        state.dashboard.partnerStats = null
      })
      .addCase(getPartnerStatsCompany.action.fulfilled, (state, action) => {
        state.dashboard.partnerStatList = action.payload.partnerStatList
        state.dashboard.userLinkedList = action.payload.userLinkedList
        state.dashboard.partnerStats = action.payload.stats
      })
      .addCase(getPartnerStatsCompany.action.rejected, (state, _action) => {})
  },
}

export const asyncActions = {
  get: get.action,
  patch: patch.action,
  fetchInterventions: fetchInterventions.action,
  fetchQuotations: fetchQuotations.action,
  fetchInvoices: fetchInvoices.action,
  fetchFactures: fetchFactures.action,
  getPartnerStatsCompany: getPartnerStatsCompany.action,
}

export const extraReducers = (builder: ActionReducerMapBuilder<IArtisanState>) => {
  get.reducer(builder)
  patch.reducer(builder)
  fetchInterventions.reducer(builder)
  fetchQuotations.reducer(builder)
  fetchInvoices.reducer(builder)
  fetchFactures.reducer(builder)
  getPartnerStatsCompany.reducer(builder)
}
