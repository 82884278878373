import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import { FactureErrorState } from '@Arti-zen/package-backoffice'
import { factureListActions } from '../../store/store.facturation'
import { Format } from 'utils/format'
import { DatePicker } from '@mui/x-date-pickers'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { EPaymentProgressStatus } from '../../store/store.facturation.interfaces'
import CsvDownloadButton from 'react-json-to-csv'
import { Wrapper, LeftBar, WrapperFilter, WrapperCheckBoxes, WrapperContent, Title } from './FactureList.styled'
import FactureList from 'components/FactureList/FactureList'
import { useDebounce } from 'hooks/useDebounce'

export default function FactureListHome(): ReactElement {
  const dispatch = useAppDispatch()
  const { displayedFactures, dateStart, dateEnd, errorList, deactivatedClient, paymentStatusList, isRefreshing } =
    useAppSelector((state) => state.facturation)
  const [search, setSearch] = useState<string>()
  const textSearched = useDebounce(search, 500)

  useEffect(() => {
    dispatch(factureListActions.setSearch(textSearched))
  }, [textSearched])

  return (
    <Wrapper>
      <LeftBar>
        <DatePicker
          label="Date de début"
          value={dateStart}
          onChange={(e) => dispatch(factureListActions.setDateStart(e))}
          timezone="Europe/Paris"
        />

        <DatePicker label="Date de fin" value={dateEnd} onChange={(e) => dispatch(factureListActions.setDateEnd(e))} />

        <WrapperFilter>
          <Title>Errors</Title>
          <WrapperCheckBoxes>
            {Object.values(FactureErrorState).map((error) => {
              return (
                <FormControlLabel
                  key={error}
                  control={
                    <Checkbox
                      onChange={() => dispatch(factureListActions.switchDeactivatedClient())}
                      checked={errorList.includes(error)}
                      size="small"
                    />
                  }
                  label={`${error} (${displayedFactures.filter((fact) => fact.errorState === error).length})`}
                  labelPlacement="end"
                  componentsProps={{ typography: { style: { fontSize: 14 } } }}
                />
              )
            })}
          </WrapperCheckBoxes>
        </WrapperFilter>

        <WrapperFilter>
          <Title>Statuts Paiement</Title>
          <WrapperCheckBoxes>
            {Object.values(EPaymentProgressStatus).map((state) => {
              return (
                <FormControlLabel
                  key={state}
                  control={
                    <Checkbox
                      onChange={() => dispatch(factureListActions.switchPaymentStatus(state))}
                      checked={paymentStatusList.includes(state)}
                      size="small"
                    />
                  }
                  label={`${state} (${displayedFactures.filter((fact) => fact.paymentProgress === state).length})`}
                  labelPlacement="end"
                  componentsProps={{ typography: { style: { fontSize: 14 } } }}
                />
              )
            })}
          </WrapperCheckBoxes>
        </WrapperFilter>
        <WrapperFilter>
          <Title>Artisan</Title>
          <WrapperCheckBoxes>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => dispatch(factureListActions.switchDeactivatedClient(!deactivatedClient))}
                  checked={deactivatedClient}
                  size="small"
                />
              }
              label={`bloqué`}
              labelPlacement="end"
              componentsProps={{ typography: { style: { fontSize: 14 } } }}
            />
          </WrapperCheckBoxes>
        </WrapperFilter>

        <CsvDownloadButton
          data={displayedFactures}
          title="Factures"
          filename={`Factures du ${dateStart.toFormat('yyyy/MM/dd')} au ${dateEnd.toFormat('yyyy/MM/dd')}`}
          style={{ margin: 5 }}
        >
          Télécharger
        </CsvDownloadButton>
      </LeftBar>

      <WrapperContent>
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Rechercher une facture (artisanId, email, numéro de facture)"
          style={{ display: 'flex', width: '50%' }}
        />
        <div
          style={{
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>
            {displayedFactures.length} factures pour{' '}
            {Format.currency(
              displayedFactures.reduce((acc, fact) => acc + +fact.amount, 0),
              2
            )}{' '}
            HT
          </span>
          <Button onClick={() => dispatch(factureListActions.getList())} variant="contained" disabled={isRefreshing}>
            {isRefreshing ? '...' : 'Refresh'}
          </Button>
        </div>

        <FactureList
          factureList={displayedFactures}
          updatePayment={(id: string) => dispatch(factureListActions.updateFacturePayment(id))}
        />
      </WrapperContent>
    </Wrapper>
  )
}
