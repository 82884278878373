import { ReactElement } from 'react'
import { DateTime, Interval } from 'luxon'

export const allMonth = ({
  dateStart,
  dateEnd,
  action,
}: {
  dateStart: DateTime
  dateEnd: DateTime
  action: (year: number, month: number) => ReactElement
}) =>
  Interval.fromDateTimes(dateStart, dateEnd)
    .splitBy({ month: 1 })
    .map((val) => ({ year: val.start.year, month: val.start.month }))
    .map(({ year, month }) => action(year, month))
    .flat()
