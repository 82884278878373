import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { DatePicker } from '@mui/x-date-pickers'
import { artisanActions } from '../../store/store.artisan'
import { Button } from '@mui/material'
import { SwapHoriz } from '@mui/icons-material'
import ArtisanDashboardClassic from './ArtisanDashboard.classic'
import ArtisanDashboardPartners from './DashboardPartnerFigures'

export default function ArtisanDashboard(): ReactElement {
  const dispatch = useAppDispatch()

  const { dateStart, dateEnd } = useAppSelector((state) => state.artisan.dashboard)
  const { companyIds } = useAppSelector((state) => state.artisan)

  const [infoDisplayed, setInfoDisplayed] = useState<'classic' | 'partner'>('classic')

  useEffect(() => {
    dispatch(artisanActions.fetchInterventions())
    dispatch(artisanActions.fetchQuotations())
    dispatch(artisanActions.fetchInvoices())
    dispatch(artisanActions.getPartnerStatsCompany(companyIds))
  }, [dateStart, dateEnd])

  return (
    <Wrapper>
      <Button
        variant="outlined"
        startIcon={<SwapHoriz />}
        onClick={() => setInfoDisplayed(infoDisplayed === 'classic' ? 'partner' : 'classic')}
        style={{ marginBottom: 20 }}
      >
        {infoDisplayed === 'classic' ? 'Stats Artizen' : 'Stats Partners'}
      </Button>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 50, marginTop: 50 }}>
        <DatePicker
          label="Date de début"
          value={dateStart}
          onChange={(e) => dispatch(artisanActions.setDateStart(e))}
          timezone="Europe/Paris"
        />

        <DatePicker label="Date de fin" value={dateEnd} onChange={(e) => dispatch(artisanActions.setDateStart(e))} />
      </div>
      {infoDisplayed === 'classic' ? <ArtisanDashboardClassic /> : <ArtisanDashboardPartners />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
