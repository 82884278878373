import { Facture, PaymentStatus, TPayment, UserArtizen } from '@Arti-zen/package-backoffice'
import { DateTime } from 'luxon'

export enum EPaymentProgressStatus {
  inProgress = 'in progress',
  paid = 'paid',
  unpaid = 'unpaid',
  unknown = 'no payment linked',
  partialSup = 'paid more',
  partialLess = 'paid less',
  moreThanOne = 'more than one payment',
  refreshing = 'resfreshing',
  refreshError = 'refresh error',
}

export const PaymentProgressTranslate = {
  [PaymentStatus.CREATED]: EPaymentProgressStatus.inProgress,
  [PaymentStatus.PENDING_CUSTOMER_APPROVAL]: EPaymentProgressStatus.inProgress,
  [PaymentStatus.PENDING_SUBMISSION]: EPaymentProgressStatus.inProgress,
  [PaymentStatus.SUBMITTED]: EPaymentProgressStatus.inProgress,
  [PaymentStatus.CONFIRMED]: EPaymentProgressStatus.paid,
  [PaymentStatus.PAID_OUT]: EPaymentProgressStatus.paid,
  [PaymentStatus.CANCELLED]: EPaymentProgressStatus.unpaid,
  [PaymentStatus.CHARGED_BACK]: EPaymentProgressStatus.unpaid,
  [PaymentStatus.CUSTOMER_APPROVAL_DENIED]: EPaymentProgressStatus.unpaid,
  [PaymentStatus.FAILED]: EPaymentProgressStatus.unpaid,
  [PaymentStatus.MANUAL]: EPaymentProgressStatus.paid,
}

export type FactureExtended = Facture & {
  paymentProgress: EPaymentProgressStatus
}

export interface IFacturationListState {
  dateStart: DateTime
  dateEnd: DateTime
  isRefreshing?: boolean
  stateList: Facture['state'][]
  errorList: Facture['errorState'][]
  deactivatedClient: boolean
  paymentStatusList: EPaymentProgressStatus[]
  factures: FactureExtended[]
  factureMissingList: TFactureMissing[]
  displayedFactures: FactureExtended[]
  paymentOrphanList: TPaymentExtended[]
  search?: string
  searchMissing?: string
}

export type TFactureMissing = {
  id: UserArtizen['id']
  user: UserArtizen
  subscriptionDate: DateTime
  resilisationDate: DateTime
  nbFactureTheo: number
  nbFactureReal: number
  diff: number
  factureTheoList: string[]
  factRealList: string[]
}

export type TPaymentExtended = TPayment & {
  artisan: UserArtizen
  paymentProgress: EPaymentProgressStatus
  chargeDateTime: DateTime
}
