import { FormControlLabel, IconButton, MenuItem, Select } from '@mui/material'
import { ReactElement } from 'react'
import { Control, Controller, FieldError, Path } from 'react-hook-form'
import styled from 'styled-components'

interface IArtisanForm<T> {
  control: Control<T>
  name: Path<T>
  label?: string
  error?: FieldError
  required?: boolean
  items: { value: any; label: string }[]
  defaultValue?: any
}

export default function SelectControl<T>({
  control,
  name,
  label,
  items,
  required = false,
  defaultValue,
}: Readonly<IArtisanForm<T>>): ReactElement {
  return (
    <Wrapper>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label={label}
            labelPlacement="top"
            required={required}
            control={
              <Select
                {...field}
                labelId={label + 'control'}
                id={label}
                label={label}
                required={required}
                margin="none"
                defaultValue={defaultValue}
                IconComponent={
                  !required && field.value
                    ? () => (
                        <IconButton
                          size="small"
                          onClick={() => {
                            field.onChange('')
                          }}
                          style={{ marginRight: 5 }}
                        >
                          X
                        </IconButton>
                      )
                    : undefined
                }
              >
                {items.map(({ value, label }) => (
                  <MenuItem key={label + value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        )}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
