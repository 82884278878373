import SelectBase from 'components/forms/Select.base'
import { useAppSelector } from 'hooks/useStore'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { ApiArtizenBO } from 'utils/artizenConnector'

export default function ArtisanPaymentOption(): ReactElement {
  const { artisan } = useAppSelector((state) => state.artisan)
  const [currentOption, setCurrentOption] = useState<'true' | 'false' | null>(null)
  const [message, setMessage] = useState<string | null>(null)

  const Input = useCallback(
    () => (
      <SelectBase
        items={[
          { value: 'true', label: 'oui' },
          { value: 'false', label: 'non' },
        ]}
        defaultValue={currentOption}
        onChange={onChange}
      />
    ),
    [currentOption]
  )

  const onChange = (value: 'true' | 'false') => {
    setMessage('Sauvegarde en cours')
    ApiArtizenBO.artizenUsers
      .createOrUpdatePaymentOption(artisan.id, value)
      .then(() => setMessage('Option sauvegardée'))
      .catch(() => setMessage('Echec de la sauvegarde'))
  }

  useEffect(() => {
    let isMount = true
    ApiArtizenBO.artizenUsers.getPaymentOption(artisan.id).then((option) => isMount && setCurrentOption(option?.value))

    return () => {
      isMount = false
    }
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center' }}>
      Option de paiement : <Input /> <span style={{ fontSize: 12, fontStyle: 'italic' }}>{message}</span>
    </div>
  )
}
