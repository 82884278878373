import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Order, TCompanyLastCall, TCompanyLastDocument } from '@Arti-zen/package-backoffice'
import { ApiArtizenBO } from 'utils/artizenConnector'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Alert, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Theme } from 'theme/theme'
import { definitionFields, TData } from './companyLastDocList.definitionFields'
import { sortData } from './companyLastDocList.helpers'

export default function CompanyLastDocList(): ReactElement {
  const [companyLastCallInfos, setCompanyLastCallInfos] = useState<TCompanyLastCall[]>([])
  const [companyLastDocInfos, setCompanyLastDocInfos] = useState<TCompanyLastDocument[]>([])
  const [selectedData, setSelectedData] = useState<TData>(null)
  const [error, setError] = useState<string>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [activeSort, setActiveSort] = useState<{ key: keyof TData; order: Order }[]>([
    { key: 'documentLastDate', order: Order.DESC },
  ])

  useEffect(() => {
    setIsLoading(true)
    let isMount = true
    ApiArtizenBO.companyStats
      .getCompanyLastDocInfos()
      .then(({ companyLastCallInfos, companyLastDocInfos }) => {
        if (isMount) {
          setCompanyLastCallInfos(companyLastCallInfos)
          setCompanyLastDocInfos(companyLastDocInfos)
        }
      })
      .catch((err) => setNewError(err?.message))

    return () => {
      isMount = false
    }
  }, [])

  const data: TData[] = useMemo(() => {
    const dataFormat = companyLastDocInfos.map((doc) => {
      return {
        ...doc,
        lastCall: companyLastCallInfos.find((call) => call.companyId === doc.companyId),
        allCall: companyLastCallInfos.filter((call) => call.companyId === doc.companyId),
      }
    })

    const dataSorted = sortData(activeSort[0].key, activeSort[0].order, dataFormat)

    setIsLoading(false)

    return dataSorted
  }, [activeSort, companyLastCallInfos, companyLastDocInfos])

  const onSort = (key: keyof TData, order: Order) => {
    setActiveSort([{ key, order }])
  }

  const fields = definitionFields(setSelectedData)

  const addNewCall = async (dataCompany: TData) => {
    try {
      const newCall = await ApiArtizenBO.companyStats.postNewCall(dataCompany.companyId)
      setCompanyLastCallInfos([newCall, ...companyLastCallInfos])
    } catch (error) {
      setNewError(error.message)
    } finally {
      setSelectedData(null)
    }
  }

  const removeLastCall = async (dataCompany: TData) => {
    try {
      await ApiArtizenBO.companyStats.deleteCall(dataCompany.lastCall.id)
      setCompanyLastCallInfos(companyLastCallInfos.filter((call) => call.id !== dataCompany.lastCall.id))
    } catch (error) {
      setNewError(error.message)
    } finally {
      setSelectedData(null)
    }
  }

  const setNewError = (errorMessage: string) => {
    setError(`Une erreur est survenue : ${errorMessage}`)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>Nb artisans : {data.length}</div>
      <TableInfinite
        data={data}
        isQueriedAllCompleted={true}
        definitionFields={fields}
        displayedFields={fields.map((def) => def.indexName)}
        nextPage={null}
        onSort={onSort}
        activeSorts={activeSort}
        isLoading={isLoading}
      />
      <Dialog open={selectedData !== null} onClose={() => setSelectedData(null)}>
        <DialogTitle
          style={{
            textAlign: 'center',
            backgroundColor: Theme.colors.blueArtizen,
            color: 'white',
          }}
        >
          Gestion des appels artisan pour {selectedData?.companyId}
        </DialogTitle>

        <DialogContent
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 20,
            padding: 20,
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            style={{ backgroundColor: Theme.colors.greenArtizen, color: 'white', width: '50%' }}
            onClick={async () => {
              await addNewCall(selectedData)
            }}
            size="large"
            variant="contained"
          >
            Ajouter nouvel appel
          </Button>
          <Button
            style={{ backgroundColor: Theme.colors.greyDark, color: 'white', width: '50%' }}
            onClick={async () => {
              await removeLastCall(selectedData)
            }}
            size="large"
            variant="contained"
          >
            Supprimer le dernier appel
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}
